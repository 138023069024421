import styled from 'styled-components';

export const StyledTitle = styled.span`
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 8px;
  color: #3d5170;
`;

export const StyledDesc = styled.span`
  font-weight: lighter;
  margin-bottom: 8px;
  font-size: 12px;
`;
