import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { ticketRequestSuccess, ticketRequestFailure } from './actions';
import history from '~/services/history';

export function* ticketRequest({ payload }) {
  try {
    toast.info('Aguarde, buscando dados...');
    const { ticketID } = payload;

    const responseTicket = yield call(api.get, `ticket`, {
      params: {
        id: ticketID,
      },
    });

    const { ticket } = responseTicket.data;

    const responseOccurrence = yield call(api.get, `occurrences`, {
      params: {
        id: ticket.occurrence_id,
      },
    });

    const { occurrence } = responseOccurrence.data;

    yield put(ticketRequestSuccess(ticket, occurrence));
    toast.success('Ticket encontrado e dados carregados...');
    return;

    // history.push(`/ticket/${ticketID}`);
  } catch (err) {
    toast.error(
      'Aconteceu algum erro na requisição, tente novamente. Caso o erro persista, contate algum administrador'
    );
    yield put(ticketRequestFailure());
    history.goBack();
  }
}

export default all([takeLatest('@ticket/TICKET_REQUEST', ticketRequest)]);
