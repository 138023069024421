import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { MdAddCircleOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import Select from './SelectInput';
import { Container, StyledTitle, StyledDesc } from './styles';
import CardTitle from '../CardTitle';
import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  solution: Yup.number()
    .required()
    .typeError('Você precisa escolher uma das opções !'),
});

export default function OccurrenceManagerCarrier({ ticketID }) {
  const [problemOptions, setProblemOptions] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false);
  const loading = false;

  useEffect(() => {
    const getProblems = async () => {
      const { data } = await api.get(`solutions`);
      data.forEach(d => {
        d.title = d.solution_desc;
      });
      setProblemOptions([...data]);
    };

    const getStatus = async () => {
      const {
        data: { ticket },
      } = await api.get(`ticket`, {
        params: {
          id: ticketID,
        },
      });

      if (ticket.status === 3) setButtonStatus(true);
    };

    getProblems();
    getStatus();
  }, [ticketID]);

  const handleSubmit = async (data, { resetForm }) => {
    const { solution } = data;

    await api.put('ticket-request', {
      ticket_id: ticketID,
      solution_id: solution,
    });

    await api.put('ticket', {
      id: ticketID,
      status: 3,
    });

    toast.success('Resolução enviada e finalizada com sucesso!');

    setButtonStatus(true);
    history.goBack();
    resetForm();
  };

  return (
    <Container>
      <CardTitle title="Tratativa Solicitada" icoName="report" />
      <Form schema={schema} onSubmit={handleSubmit}>
        <div>
          <StyledTitle>Solicitação: </StyledTitle>
          <StyledDesc>
            Lorem Ipsum is simply dummy text of the printing
          </StyledDesc>
        </div>
        <Select
          name="solution"
          placeholder="Selecione..."
          options={problemOptions || ['nenhum']}
          multiple={false}
        />
        <footer>
          <button type="submit" disabled={buttonStatus}>
            <MdAddCircleOutline size={20} color="#fff" />
            <span>{!loading ? 'Enviar resolução' : 'Aguarde...'}</span>
          </button>
        </footer>
      </Form>
    </Container>
  );
}

OccurrenceManagerCarrier.propTypes = {
  typeOptions: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  ticketID: PropTypes.number.isRequired,
};

OccurrenceManagerCarrier.defaultProps = {
  typeOptions: { id: 0, title: '' },
};
