import { styled as styledMaterial } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

export const StyledSender = styledMaterial(Typography)({
  flexBasis: '33.33%',
  fontSize: '16px',
});

export const StyledDate = styledMaterial(Typography)({
  color: '#7a7a7a',
  flexBasis: '33.33%',
  fontSize: '16px',
  flex: 1,
});
