import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import api from '~/services/api';
import BeTable from '~/components/TableManual';
import { Container } from './styles';

const isPlainObj = o =>
  Boolean(
    o &&
      o.constructor &&
      o.constructor.prototype &&
      o.constructor.prototype.hasOwnProperty.call('isPrototypeOf')
  );

const flattenObj = (obj, keys = []) => {
  return Object.keys(obj).reduce((acc, key) => {
    return Object.assign(
      acc,
      isPlainObj(obj[key])
        ? flattenObj(obj[key], keys.concat(key))
        : { [keys.concat(key).join('.')]: obj[key] }
    );
  }, {});
};

const virtualUrlDate = obj => {
  const result = [];

  obj.forEach(v => {
    const url = `/ticket/${v.id}`;
    const data = new Date(v.createdAt).toLocaleDateString('pt-BR');
    v.createdAt = data;
    v.url = url;
    result.push(v);
  });

  return result;
};

const tableColumns = [
  { title: 'ID', field: 'id' },
  { title: 'Nota Fiscal', field: 'occurrence.invoice' },
  { title: 'Num. Pedido', field: 'occurrence.order_number' },
  { title: 'Transportadora', field: 'occurrence.company.name' },
  { title: 'Problema', field: 'problem.description' },
  { title: 'Prazo', field: 'problem.days_limit' },
  { title: 'Criado em', field: 'createdAt' },
  {
    title: 'Detalhes',
    field: 'url',
    render: rowData => (
      <Link to={rowData.url} style={{ alignContent: 'center' }}>
        <SettingsIcon />
      </Link>
    ),
  },
];

export default function CarrierOccurrences() {
  const [occurrences, setOccurrences] = useState([]);
  const [columns, setColumns] = useState([]);
  const role = useSelector(state => state.user.company.role);

  useEffect(() => {
    const getTickets = async () => {
      let result = [];
      const {
        data: { tickets },
      } = await api.get('ticket', {
        params: { status: 2 },
      });

      tickets.forEach(e => {
        result.push(flattenObj(e));
      });
      result = virtualUrlDate(result);

      setOccurrences([...result]);
    };

    setColumns([...tableColumns]);
    getTickets();
  }, [role.id]);

  return (
    <Container>
      <BeTable
        dados={occurrences}
        colunas={columns}
        titulo="Ocorrências Transportadoras"
      />
    </Container>
  );
}
