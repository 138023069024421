export const menuAdmin = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    title: 'Ocorrências em Aberto',
    path: '/open',
    icon: 'new_releases',
  },
  {
    title: 'Ocorrências Finalizadas',
    path: '/closed',
    icon: 'check_circle',
  },
  {
    title: 'Ocorrências Transportadora',
    path: '/carriers',
    icon: 'local_shipping',
  },
  {
    title: 'Ocorrências Expiradas',
    path: '/expired',
    icon: 'schedule',
  },
  {
    title: 'Mailing',
    path: '/mailing',
    icon: 'mail',
  },
  {
    title: 'Gerenciar Usuários',
    path: '/user-manager',
    icon: 'people',
  },
];

export const menuCallCenter = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    title: 'Ocorrências em Aberto',
    path: '/open',
    icon: 'new_releases',
  },
  {
    title: 'Ocorrências Finalizadas',
    path: '/closed',
    icon: 'check_circle',
  },
  {
    title: 'Ocorrências Expiradas',
    path: '/expired',
    icon: 'schedule',
  },
];

export const menuTransportadora = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    title: 'Ocorrências em Aberto',
    path: '/open',
    icon: 'new_releases',
  },
  {
    title: 'Ocorrências Finalizadas',
    path: '/closed',
    icon: 'check_circle',
  },
];
