import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import CardTitle from '../CardTitle';
import api from '~/services/api';

// import { Container } from './styles';

async function getTicketList(ocId) {
  const {
    data: { ticket },
  } = await api.get(`ticket`, {
    params: {
      occurrence_id: ocId,
    },
  });

  ticket.sort((a, b) => {
    if (a.id > b.id) return 1;
    if (a.id < b.id) return -1;
    return 0;
  });
  return ticket;
}

class OccurrenceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketList: [],
    };
  }

  async componentDidMount() {
    const { occurrence_id } = this.props;
    const tickets = await getTicketList(occurrence_id);

    this.setState({ ticketList: [...tickets] });
  }

  render() {
    const { ticketList } = this.state;
    return (
      <div style={{ height: '100%' }}>
        <CardTitle title="Tickets referente a ocorrência" icoName="view_list" />
        <CardContent elevation={0}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Data</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketList.map(ticket => (
                <TableRow key={ticket.id}>
                  <TableCell>{ticket.id}</TableCell>
                  <TableCell>
                    {new Date(ticket.createdAt).toLocaleDateString('pt-BR')}
                  </TableCell>
                  <TableCell align="right">
                    {ticket.status_key.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </div>
    );
  }
}

OccurrenceList.propTypes = {
  occurrence_id: PropTypes.number.isRequired,
};

export default OccurrenceList;
