import React, { useEffect, useState } from 'react';
import {
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';
import CardTitle from '../CardTitle';
import api from '~/services/api';
// import { Container } from './styles';

const headers = [
  { name: 'ID' },
  { name: 'Consultor' },
  { name: 'Transportadora' },
  { name: 'Problema' },
  { name: 'Prazo' },
  { name: 'Status' },
  { name: 'Entrada' },
];

export default function RecentTickets() {
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    const getRecentTickets = async () => {
      const { data } = await api.get(`ticket`, {
        params: { status: 1, limit: 5 },
      });
      setTickets([...data.tickets]);
    };
    getRecentTickets();
  }, []);

  return (
    <Paper>
      <CardTitle title="Tickets Recentes (Abertos)" icoName="list" />
      <CardContent elevation={0}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell style={{ fontWeight: 'bold' }} key={header.name}>
                  {header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map(ticket => (
              <TableRow key={ticket.id}>
                <TableCell>{ticket.id}</TableCell>
                <TableCell>{ticket.occurrence.cj_name}</TableCell>
                <TableCell>{ticket.occurrence.company.name}</TableCell>
                <TableCell>{ticket.problem.description}</TableCell>
                <TableCell>{ticket.problem.days_limit}</TableCell>
                <TableCell>{ticket.status_key.status}</TableCell>
                <TableCell>
                  {new Date(ticket.createdAt).toLocaleDateString('pt-BR')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Paper>
  );
}
