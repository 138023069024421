import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
  Icon,
} from '@material-ui/core';
import api from '~/services/api';
import CardTitle from '../CardTitle';

import { StyledSender, StyledDate } from './styles';

const getComments = async ticketId => {
  const { data } = await api.get(`comments`, {
    params: { ticket_id: ticketId },
  });
  return data;
};

const RenderComment = ({ user, companyType, date, comment }) => {
  return (
    <>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<Icon>expand_more</Icon>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledSender>
            {companyType}: {user}
          </StyledSender>
          <StyledDate align="right">{date}</StyledDate>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            backgroundColor: '#e0e0e0',
            margin: '8px',
            borderRadius: '6px',
          }}
        >
          <Typography style={{ fontSize: '14px' }}>{comment}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

export default class TicketComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [
        {
          id: 0,
          comment: '',
          createdAt: '',
          users: {
            name: '',
            company: {
              role: {
                role_name: '',
              },
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const { ticketId } = this.props;

    const commentResponse = await getComments(ticketId);
    this.setState({ comments: [...commentResponse] });
  }

  render() {
    const { comments } = this.state;
    return (
      <div>
        <CardTitle title="Comentários Antigos" icoName="view_list" />
        {comments.map(cmt => (
          <RenderComment
            key={cmt.id}
            user={cmt.users.name}
            companyType={cmt.users.company.role.role_name}
            date={new Date(cmt.createdAt).toLocaleString('pt-BR')}
            comment={cmt.comment}
          />
        ))}
      </div>
    );
  }
}

TicketComments.propTypes = {
  ticketId: PropTypes.number.isRequired,
};

RenderComment.propTypes = {
  user: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};
