import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CardTitle from '../CardTitle';

const data = [
  {
    name: 'abr.',
    solicitacoes: 4000,
    vencidas: 2400,
  },
  {
    name: 'mai.',
    solicitacoes: 3000,
    vencidas: 1398,
  },
  {
    name: 'jun.',
    solicitacoes: 2000,
    vencidas: 9800,
  },
  {
    name: 'jul.',
    solicitacoes: 2780,
    vencidas: 3908,
  },
  {
    name: 'ago.',
    solicitacoes: 1890,
    vencidas: 4800,
  },
  {
    name: 'set.',
    solicitacoes: 2390,
    vencidas: 3800,
  },
  {
    name: 'out.',
    solicitacoes: 3490,
    vencidas: 4300,
  },
];

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    textAlign: 'left',
    borderRadius: '6px',
    overflow: 'hidden',
  },
});

function RenderAreaChart() {
  return (
    <ResponsiveContainer width="100%" height="70%">
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#44bb55" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#44bb55" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis type="number" tick={{ fontSize: 12, fill: '#5A6169' }} />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="solicitacoes"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="vencidas"
          stroke="#44bb55"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
export default function TicketChart() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardTitle title="Gráfico" icoName="graphic_eq" />
      <RenderAreaChart />
    </Card>
  );
}
