import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }

  textarea {
    background: rgba(200, 200, 200, 0.4);
    border: none;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    border-radius: 4px;
    padding: 15px 20px;
    margin: 1.4% 1.4%;
    margin-bottom: 10px;
    height: 64px;
    resize: vertical;
    &::placeholder {
      color: #888;
    }
  }
  textarea + span {
    font-size: 14px;
    color: #f94d6a;
    font-weight: lighter;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 8px 0;
  }
  hr {
    border: none;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 30px 0;
  }
  footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.4%;
    margin-right: 1.4%;
    button {
      background: #3e5373;
      color: #fff;
      font-size: 0.7rem;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      text-transform: uppercase;

      &:enabled {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:disabled {
        background: #3e5373;
        opacity: 0.6;
      }

      &:hover:enabled {
        background: ${darken(0.05, '#3e5373')};
        box-shadow: 0px 6px 7px -7px rgba(0, 0, 0, 0.2),
          0px 12px 19px 3px rgba(0, 0, 0, 0.14),
          0px 9px 12px 8px rgba(0, 0, 0, 0.12);
      }
      span {
        margin-left: 10px;
      }
    }
  }
`;
