import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  ticket: {
    problem: {
      description: '',
    },
    status_key: {
      status: '',
    },
  },
};

export default function ticket(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@ticket/TICKET_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@ticket/TICKET_SUCCESS': {
        draft.ticket = action.payload.ticket;
        draft.loading = false;
        break;
      }
      case '@ticket/TICKET_FAILURE': {
        draft.loading = false;
        draft.ticket = null;
        break;
      }
      default:
    }
  });
}
