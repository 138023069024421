import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { Container } from './styles';
import Card from '~/components/Card';
import DoubleBarChart from '~/components/DoubleBarChart';
import RecentTickets from '~/components/RecentTickets';
import api from '~/services/api';
import TicketChart from '~/components/TicketChart';

export default function DashboardAdmin() {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const dashStats = async () => {
      const { data } = await api.get('dashboard-stats');
      setStats(data);
    };

    dashStats();
  }, []);

  const cards = [
    {
      cardTitle: 'Ocorrências Total',
      cardValue: stats.ticket ? stats.ticket.total : '0',
      cardIcon: 'settings',
      cardColor: 'primary',
    },
    {
      cardTitle: 'Ocorrências Abertas',
      cardValue: stats.ticket ? stats.ticket.open : '0',
      cardIcon: 'check_circle_outline',
      cardColor: 'secondary',
    },
    {
      cardTitle: 'Ocorrências Finalizadas',
      cardValue: stats.ticket ? stats.ticket.closed : '0',
      cardIcon: 'favorite',
      cardColor: 'action',
    },
    {
      cardTitle: 'Ocorrências CallCenter',
      cardValue: stats.ticket ? stats.ticket.call_center : '0',
      cardIcon: 'favorite',
      cardColor: 'action',
    },
    {
      cardTitle: 'Ocorrências TRP',
      cardValue: stats.ticket ? stats.ticket.carrier : '0',
      cardIcon: 'favorite',
      cardColor: 'action',
    },
  ];

  return (
    <Container>
      <Grid container spacing={3} direction="column">
        <Grid container spacing={3} alignItems="stretch" item xs>
          {cards.map(card => (
            <Grid item xs={4} key={`${card.cardTitle}gi`}>
              <Card
                key={card.cardTitle}
                cardTitle={card.cardTitle}
                cardValue={card.cardValue}
                cardIcon={card.cardIcon}
                cardColor={card.cardColor}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={3} item xs>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="stretch">
              <Grid item xs={8}>
                <DoubleBarChart />
              </Grid>
              <Grid
                item
                xs={4}
                container
                direction="column"
                justify="space-between"
              >
                <TicketChart />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <RecentTickets />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
