import styled from 'styled-components';

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;

    background: #f6f6f7;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 9px 20px;
    font-size: 10px;
    color: #6c6472;
    border-radius: 4px;
    margin-bottom: 10px;
    input,
    textarea {
      background: #f6f6f7;
      border: 0;
      color: #2e2236;
      margin: 5px 0 5px;
      &::placeholder {
        color: #d1d0d1;
      }
      textarea {
        max-width: 100%;
      }
    }
    span {
      display: none;
    }
  }
`;
