import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CardTitle from '../CardTitle';

const data = [
  {
    name: 'abr.',
    solicitacoes: 4000,
    vencidas: 2400,
  },
  {
    name: 'mai.',
    solicitacoes: 3000,
    vencidas: 1398,
  },
  {
    name: 'jun.',
    solicitacoes: 2000,
    vencidas: 9800,
  },
  {
    name: 'jul.',
    solicitacoes: 2780,
    vencidas: 3908,
  },
  {
    name: 'ago.',
    solicitacoes: 1890,
    vencidas: 4800,
  },
  {
    name: 'set.',
    solicitacoes: 2390,
    vencidas: 3800,
  },
  {
    name: 'out.',
    solicitacoes: 3490,
    vencidas: 4300,
  },
];

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    textAlign: 'left',
    borderRadius: '6px',
    overflow: 'hidden',
  },
});

function RenderBarChart() {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="vencidas" fill="#8884d8" />
        <Bar dataKey="solicitacoes" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
}
export default function DoubleBarChart() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardTitle title="Gráfico" icoName="bar_chart" />
      <RenderBarChart />
    </Card>
  );
}
