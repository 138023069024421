import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import DashboardMain from '../pages/Dashboard/Main';
import Profile from '../pages/Profile';

import UserManager from '~/pages/UserManager';
import TicketDetailsMain from '~/pages/TicketDetails';
import OpenOccurrences from '~/pages/Occurrences/Open';
import ClosedOccurrences from '~/pages/Occurrences/Closed';
import Mailing from '~/pages/Mailing';
import ExpiredOccurrences from '~/pages/Occurrences/Expired';
import CarrierOccurrences from '~/pages/Occurrences/Carriers';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" exact component={SignUp} />
      <Route path="/dashboard" component={DashboardMain} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/open" component={OpenOccurrences} isPrivate />
      <Route path="/closed" component={ClosedOccurrences} isPrivate />
      <Route path="/carriers" component={CarrierOccurrences} isPrivate />
      <Route path="/expired" component={ExpiredOccurrences} isPrivate />
      <Route path="/ticket/:id" component={TicketDetailsMain} isPrivate />
      <Route path="/user-manager" component={UserManager} isPrivate />
      <Route path="/mailing" component={Mailing} isPrivate />
    </Switch>
  );
}
