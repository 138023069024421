import React, { useState, useEffect } from 'react';
import {
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CardTitle from '~/components/CardTitle';
import api from '~/services/api';
import history from '~/services/history';

import {
  Container,
  ImageProfile,
  CellEnable,
  StyledCellHeader,
  StyledCellContent,
} from './styles';

const UserManager = () => {
  const userCompany = useSelector(state => state.user.company);
  const [users, setUsers] = useState([]);
  const avatarUrl = `https://ui-avatars.com/api/?rounded=true&background=203a43&font-size=0.4&color=fff&name=`;
  if (userCompany.role.id !== 1)
    if (userCompany.role.id !== 4) history.push('/dashboard');

  useEffect(() => {
    const getUsersList = async () => {
      const { data } = await api.get(`users`, {
        params: { all: true },
      });

      setUsers([...data.users]);
    };

    getUsersList();
  }, []);

  const handleEnabled = async user => {
    const dataUpdate = await api.put(`users`, {
      userId: user.id,
      enabledState: true,
      enabled: !user.enabled,
    });

    toast.success(dataUpdate.data.success);

    const { data } = await api.get(`users`, {
      params: { all: true },
    });

    setUsers([...data.users]);
  };

  return (
    <Container>
      <Paper>
        <CardContent>
          <CardTitle title="Gerenciar Usuários" icoName="people" />
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledCellHeader>Avatar</StyledCellHeader>
                <StyledCellHeader>Nome</StyledCellHeader>
                <StyledCellHeader>Email</StyledCellHeader>
                <StyledCellHeader>Empresa</StyledCellHeader>
                <StyledCellHeader>Tipo Empresa</StyledCellHeader>
                <StyledCellHeader>Ativo</StyledCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id}>
                  <StyledCellContent>
                    {user.file ? (
                      <ImageProfile src={user.file.url} alt="" />
                    ) : (
                      <ImageProfile src={`${avatarUrl}${user.name}`} alt="" />
                    )}
                  </StyledCellContent>
                  <StyledCellContent>{user.name}</StyledCellContent>
                  <StyledCellContent>{user.email}</StyledCellContent>
                  <StyledCellContent>{user.company.name}</StyledCellContent>
                  <StyledCellContent>
                    {user.company.role.role_name}
                  </StyledCellContent>
                  <CellEnable
                    enabled={user.enabled}
                    onClick={() => handleEnabled(user)}
                  >
                    {user.enabled ? 'Ativado' : 'Desativado'}
                  </CellEnable>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Paper>
    </Container>
  );
};

export default UserManager;
// https://ui-avatars.com/api/?rounded=true&name=${profile.name}
