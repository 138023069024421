import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ticketRequest } from '~/store/modules/ticket/actions';

import TicketDetailsCallCenter from './CallCenter';
import TicketDetailsCarrier from './Carrier';
import TicketDetailsAdmin from './Admin';

export default function TicketDetailsMain(props) {
  let wichRender;
  const dispatch = useDispatch();
  const company = useSelector(state => state.user.company);
  const role = company.role.id;
  const {
    match: {
      params: { id },
    },
  } = props;

  const modelLoad = () => {
    if (role === 1 || role === 4) wichRender = <TicketDetailsAdmin id={id} />;
    if (role === 2) wichRender = <TicketDetailsCarrier id={id} />;
    if (role === 3) wichRender = <TicketDetailsCallCenter id={id} />;
    return '';
  };

  useEffect(() => {
    const loadData = async () => {
      await dispatch(ticketRequest(id));
    };
    loadData();
  }, [dispatch, id]);

  modelLoad();

  return <>{wichRender}</>;
}

TicketDetailsMain.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
};
