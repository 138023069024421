import React from 'react';
import { useSelector } from 'react-redux';

import DashboardAdmin from '../Admin';
import DashboardCallCenter from '../CallCenter';
import DashboardCarrier from '../Carrier';

export default function DashboardMain() {
  const company = useSelector(state => state.user.company);
  const role = company.role.id;
  let wichRender;

  if (role === 1 || role === 4) wichRender = <DashboardAdmin />;
  else if (role === 2) wichRender = <DashboardCarrier />;
  else if (role === 3) wichRender = <DashboardCallCenter />;

  return <>{wichRender}</>;
}
