import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import { StyledPaper, CardTitle, CardValue, CardIcon } from './styles';

export default function Card({ cardTitle, cardValue, cardIcon, cardColor }) {
  return (
    <StyledPaper>
      <div>
        <CardTitle>{cardTitle}</CardTitle>
        <CardValue>{cardValue}</CardValue>
      </div>
      <CardIcon>
        <Icon color={cardColor}>{cardIcon}</Icon>
      </CardIcon>
    </StyledPaper>
  );
}

Card.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  cardIcon: PropTypes.string.isRequired,
  cardColor: PropTypes.string.isRequired,
};
