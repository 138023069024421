import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { Container } from './styles';

export default function ExcelInput() {
  const { defaultValue, registerField } = useField('file');

  const [file] = useState(defaultValue && defaultValue.id);
  // const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'mailing_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [ref.current]); // eslint-disable-line

  async function handleChange(e) {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    await api.post('mailing', data);

    toast.success(`Arquivo enviado com sucesso ...`);
  }

  return (
    <Container>
      <label htmlFor="mailing">
        <input
          type="file"
          id="mailing"
          // accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </label>
    </Container>
  );
}
