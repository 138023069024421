import styled from 'styled-components';
import { Container, Paper } from '@material-ui/core';
import { styled as styledMaterial } from '@material-ui/styles';

export const ContainerDiv = styled.div`
  margin: 2% 5% auto;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
`;

export const StyledContainer = styledMaterial(Container)({
  paddingTop: 4,
  paddingBottom: 4,
  borderBottom: '5px',
});

export const StyledTitle = styled.span`
  font-weight: bolder;
  color: #3d5170;
`;

export const StyledPaper = styledMaterial(Paper)({
  height: '100%',
});
