import styled from 'styled-components';
import { darken } from 'polished';
import { styled as styledMaterial } from '@material-ui/styles';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  form {
    height: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    div {
      padding: 4px;
    }
  }

  select {
    font-family: 'Roboto', sans-serif;
    padding: 7px 10px;
    margin-bottom: 18px;
  }

  footer {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    button {
      background: #3e5373;
      color: #fff;
      font-size: 0.7rem;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      text-transform: uppercase;

      &:enabled {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:disabled {
        background: #3e5373;
        opacity: 0.6;
        box-shadow: none !important;
      }

      &:hover:enabled {
        background: ${darken(0.05, '#3e5373')};
        box-shadow: 0px 6px 7px -7px rgba(0, 0, 0, 0.2),
          0px 12px 19px 3px rgba(0, 0, 0, 0.14),
          0px 9px 12px 8px rgba(0, 0, 0, 0.12);
      }
      &:not(:first-of-type) {
        margin-left: 20px;
      }
      span {
        margin-left: 10px;
      }
    }
  }
`;

export const StyledButton = styledMaterial(Button)({
  borderRadius: '22px',
  border: '1px solid',
  borderColor: '#333',
  paddingRight: '15px',
  background: props => (props.active ? '#28a745' : '#fff9'),
  fontFamily: 'Roboto',
  textTransform: 'none',
  textAlign: 'center',

  '&:hover': {
    color: props => (props.active ? '#ffffff' : '#000000'),
    background: props => (props.active ? '#228c3b' : '#fff9'),
  },
});

export const StyledTitle = styled.span`
  font-weight: bolder;
  font-size: 14px;
  color: #3d5170;
`;

export const StyledDesc = styled.span`
  font-weight: lighter;
  margin-bottom: 8px;
  font-size: 12px;
`;
