import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import ticket from './ticket/sagas';
import occurrence from './occurrence/sagas';

export default function* rootSaga() {
  return yield all([auth, user, ticket, occurrence]);
}
