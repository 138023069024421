import React, { useState, useEffect } from 'react';

import {
  Paper,
  CardContent,
  Grid,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '~/services/api';
import CardTitle from '~/components/CardTitle';
import ExcelInput from './ExcelInput';

import { Container } from './styles';

// const mailings = [
//   { id: 6, date: '20/11/2019', amount: 34 },
//   { id: 5, date: '19/11/2019', amount: 10 },
//   { id: 4, date: '18/11/2019', amount: 16 },
//   { id: 3, date: '17/11/2019', amount: 109 },
//   { id: 2, date: '16/11/2019', amount: 128 },
//   { id: 1, date: '25/11/2019', amount: 113 },
// ];

export default function Mailing() {
  const [mailings, setMailings] = useState([
    { id: 1, createdAt: '01/01/2020', size: 1 },
    { id: 2, createdAt: '01/01/2020', size: 2 },
  ]);

  useEffect(() => {
    const getRecentMailing = async () => {
      const { data } = await api.get(`mailing`);
      setMailings(data.mails);
    };
    getRecentMailing();
  }, []);

  async function handleSubmit() {
    toast.success(`Enviando arquivo ...`);
  }

  return (
    <Container>
      <Grid container spacing={4} alignItems="baseline">
        <Grid item xs={12} md={6} lg={6}>
          <Paper>
            <CardTitle title="Enviar Mailing" icoName="list" />
            <CardContent elevation={0}>
              <Form onSubmit={handleSubmit}>
                <ExcelInput name="attach" type="file" />

                {/* <button type="submit">Enviar arquivo</button> */}
              </Form>
            </CardContent>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Paper>
            <CardTitle title="Mailing antigos" icoName="list" />
            <CardContent elevation={0}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Data</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      Quantidade
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mailings.map(mailing => (
                    <TableRow key={mailing.id}>
                      <TableCell>{mailing.id}</TableCell>
                      <TableCell>
                        {new Date(mailing.createdAt).toLocaleDateString(
                          'pt-BR'
                        )}
                      </TableCell>
                      <TableCell align="right">{mailing.size}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
