import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Grid, CardContent } from '@material-ui/core';
import { StyledTitle, StyledDesc } from './styles';
import CardTitle from '../CardTitle';

const gridStyle = {
  marginBottom: '18px',
};

const RenderData = ({ title, description }) => {
  return (
    <Grid item xs={6} style={gridStyle}>
      <StyledTitle>{title}</StyledTitle>
      <br />
      <StyledDesc>{description}</StyledDesc>
    </Grid>
  );
};

export default function Occurrence() {
  const { ticket, loading } = useSelector(state => state.ticket);
  const occurrence = useSelector(state => state.occurrence.occurrence);
  const [occurrenceData, setOccurrenceData] = useState();
  const [ticketData, setTicketData] = useState();

  useEffect(() => {
    setOccurrenceData(occurrence);
    setTicketData({ ticket, loading });
  }, [loading, occurrence, ticket]);

  return (
    <>
      <CardTitle title="Detalhes Ocorrência" icoName="assignment" />
      <CardContent elevation={0}>
        {occurrenceData != null ? (
          <>
            <Grid container spacing={3}>
              <RenderData
                title="Nome Consultor"
                description={occurrenceData.cj_name}
              />
              <RenderData
                title="Código Consultor"
                description={occurrenceData.cj_cod}
              />
            </Grid>
            <Grid container spacing={3}>
              <RenderData
                title="Nota Fiscal"
                description={occurrenceData.invoice}
              />
              <RenderData
                title="Número do Pedido"
                description={occurrenceData.order_number}
              />
            </Grid>
            <Grid container spacing={3}>
              <RenderData
                title="Transportadora Responsável"
                description={occurrenceData.company.name}
              />
              <RenderData
                title="Entrada Ocorrência"
                description={new Date(
                  occurrenceData.createdAt
                ).toLocaleDateString('pt-BR')}
              />
            </Grid>
            <Grid container spacing={3}>
              <RenderData
                title="Problema Relatado"
                description={ticketData.ticket.problem.description}
              />
            </Grid>
          </>
        ) : (
          <>
            <h1>Esperando resultado...</h1>
          </>
        )}
      </CardContent>
    </>
  );
}

RenderData.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
