import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent } from '@material-ui/core';
import { MdAddCircleOutline } from 'react-icons/md';

import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { updateProfileRequest } from '~/store/modules/user/actions';
import {
  Container,
  AccountTitle,
  MyAccountDiv,
  CardTitle,
  HorizontalLine,
} from './styles';

import AvatarInput from './AvatarInput';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe seu nome completo.'),
  email: Yup.string()
    .email('Informe um e-mail válido.')
    .required('O e-mail é obrigatório'),
  oldPassword: Yup.string(),
  password: Yup.string().when('oldPassword', (oldPassword, field) =>
    oldPassword
      ? field
          .min(6, 'A nova senha deve conter no mínimo 6 caracteres.')
          .required('Informe sua nova senha.')
      : field
  ),
  confirmPassword: Yup.string().when('password', (password, field) => {
    return password
      ? field.oneOf(
          [Yup.ref('password')],
          'Senhas não coincidem. Tente novamente.'
        )
      : field;
  }),
  avatar_id: Yup.number(),
});

function TitleDiv() {
  return (
    <MyAccountDiv className="page-header row no-gutters py-3">
      <AccountTitle className="page-subtitle">Minha Conta</AccountTitle>
    </MyAccountDiv>
  );
}

const Profile = () => {
  const loading = false;
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);

  function handleSubmit(data) {
    dispatch(updateProfileRequest(data));
  }

  return (
    <Container>
      <TitleDiv />
      <Card style={{ borderRadius: '8px', flexGrow: 1 }}>
        <CardContent>
          <CardTitle>Detalhes da Conta</CardTitle>
          <HorizontalLine />

          <Container>
            <Form initialData={profile} onSubmit={handleSubmit} schema={schema}>
              <AvatarInput name="avatar_id" />
              <Input type="text" name="name" placeholder="Nome completo" />
              <Input
                type="email"
                name="email"
                placeholder="Digite seu e-mail"
                autoComplete="username"
              />

              <hr />

              <h6>
                Caso deseje atualizar sua senha, insira a mesma abaixo
                <br />
                Do contrário, deixe os campos vazios.
              </h6>

              <Input
                type="password"
                name="oldPassword"
                autoComplete="current-password"
                placeholder="Sua senha atual"
              />
              <Input
                type="password"
                name="password"
                autoComplete="new-password"
                placeholder="Nova senha"
              />
              <Input
                type="password"
                name="confirmPassword"
                autoComplete="new-password"
                placeholder="Confirmação de senha"
              />

              <footer>
                <button type="submit">
                  <MdAddCircleOutline size={20} color="#fff" />
                  <span>{!loading ? 'Salvar perfil' : 'Aguarde...'}</span>
                </button>
              </footer>
            </Form>
          </Container>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Profile;
