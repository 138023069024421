import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import CardTitle from '../CardTitle';
import api from '~/services/api';

// import { Container } from './styles';

async function getRequestList(id) {
  const { data } = await api.get(`ticket-request`, {
    params: {
      ticket_id: id,
    },
  });

  data.sort((a, b) => {
    if (a.id > b.id) return 1;
    if (a.id < b.id) return -1;
    return 0;
  });

  return data;
}

function requestRender(requestList) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }} align="center">
            ID
          </TableCell>
          <TableCell style={{ fontWeight: 'bold' }} align="center">
            Problema
          </TableCell>
          <TableCell style={{ fontWeight: 'bold' }} align="right">
            Prazo Limite
          </TableCell>
          <TableCell style={{ fontWeight: 'bold' }} align="center">
            Data de entrada
          </TableCell>
          <TableCell style={{ fontWeight: 'bold' }} align="center">
            Status da Requisição
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {requestList.map(request => (
          <TableRow key={request.id}>
            <TableCell>{request.id}</TableCell>
            <TableCell align="right">{request.problem.description}</TableCell>
            <TableCell align="center">{request.problem.days_limit}</TableCell>
            <TableCell>
              {new Date(request.createdAt).toLocaleDateString('pt-BR')}
            </TableCell>
            <TableCell align="center">
              {request.finished ? 'Finalizado' : 'Em Aberto'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

class RequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
    };
  }

  async componentDidMount() {
    const { id } = this.props;
    const requests = await getRequestList(id);

    this.setState({ requestList: [...requests] });
  }

  render() {
    const { requestList } = this.state;
    return (
      <div style={{ height: '100%' }}>
        <CardTitle title="Requisições anteriores" icoName="view_list" />
        <CardContent elevation={0} />
        {requestList.length === 0 ? (
          <div align="center">Nenhuma informação a exibir</div>
        ) : (
          requestRender(requestList)
        )}
      </div>
    );
  }
}

RequestList.propTypes = {
  id: PropTypes.number.isRequired,
};

export default RequestList;
