import produce from 'immer';

const INITIAL_STATE = {
  occurrence: {
    cj_name: '',
    cj_cod: '',
    invoice: '',
    order_number: '',
    company: {
      name: '',
    },
    createdAt: '',
  },
  occurrences: null,
};

export default function occurrence(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@ticket/TICKET_SUCCESS': {
        draft.occurrence = action.payload.occurrence;
        draft.occurrences = action.payload.occurrences;
        break;
      }
      default:
    }
  });
}
