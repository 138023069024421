import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Form, Textarea } from '@rocketseat/unform';
import { MdAddCircleOutline } from 'react-icons/md';

import CardTitle from '../CardTitle';

import { Container } from './styles';
import api from '~/services/api';

const schema = Yup.object().shape({
  comment: Yup.string()
    .min(4, 'Comentário curto demais para ser válido.')
    .required('É necessário inserir um comentário válido'),
});

const getStatus = async ticketId => {
  const {
    data: { ticket },
  } = await api.get(`ticket`, {
    params: {
      id: ticketId,
    },
  });

  if (ticket.status === 3) return true;
  return false;
};

export default class CommentWrite extends Component {
  constructor(props) {
    super(props);
    const { ticketId } = this.props;
    this.state = {
      ticketId,
      loading: false,
      buttonStatus: false,
    };
  }

  async componentDidMount() {
    const { ticketId } = this.state;
    const bsRes = await getStatus(ticketId);
    this.setState({ buttonStatus: bsRes });
  }

  handleSubmit = async (data, { resetForm }) => {
    const { ticketId } = this.state;
    this.setState({ loading: true });

    await api.post(`/comments`, {
      ticket_id: ticketId,
      comment: data.comment,
    });

    resetForm();
    this.setState({ loading: false });
  };

  render() {
    const { loading, buttonStatus } = this.state;

    return (
      <Container>
        <CardTitle title="Novo Comentário" icoName="assignment" />
        <Form schema={schema} onSubmit={this.handleSubmit}>
          <Textarea
            type="text"
            name="comment"
            placeholder="Insira aqui seu comentário.."
            disabled={loading}
          />
          <footer>
            <button type="submit" disabled={buttonStatus}>
              <MdAddCircleOutline size={20} color="#fff" />
              <span>{!loading ? 'Enviar Comentário' : 'Aguarde...'}</span>
            </button>
          </footer>
        </Form>
      </Container>
    );
  }
}

CommentWrite.propTypes = {
  ticketId: PropTypes.number.isRequired,
};
