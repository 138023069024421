export function ticketRequest(ticketID) {
  return {
    type: '@ticket/TICKET_REQUEST',
    payload: { ticketID },
  };
}

export function ticketRequestSuccess(ticket, occurrence) {
  return {
    type: '@ticket/TICKET_SUCCESS',
    payload: { ticket, occurrence },
  };
}

export function ticketRequestFailure() {
  return {
    type: '@ticket/TICKET_FAILURE',
  };
}
