import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  padding: 2%;
  form {
    display: flex;
    flex-direction: column;

    input {
      background: rgba(200, 200, 200, 0.2);
      border: 0;
      border-radius: 4px;
      height: 44px;
      font-size: 18px;
      color: #333;
      padding: 0px 15px;
      margin-bottom: 10px;
      &::placeholder {
        color: #888;
      }
    }
    input + span {
      font-size: 14px;
      color: #f94d6a;
      font-weight: bold;
      margin: 0 0 8px 0;
    }
    hr {
      border: none;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      margin: 30px 0;
    }
    footer {
      display: flex;
      justify-content: flex-end;
      button {
        background: #3e5373;
        color: #fff;
        font-size: 16px;
        text-align: center;
        height: 42px;
        border: none;
        border-radius: 4px;
        font-weight: bold;
        margin-top: 5px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        &:hover {
          background: ${darken(0.05, '#678abf')};
        }
        span {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const AccountTitle = styled.span`
  text-transform: uppercase;
`;

export const MyAccountDiv = styled.div`
  margin-bottom: 2%;
`;

export const CardTitle = styled.h6`
  display: block;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  /* margin: 0 !important; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  color: #3d5170;
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  border-bottom: 1px solid #e1e5eb !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(225, 229, 235) !important;
  border: 1px;
`;
