import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, CardContent, Icon } from '@material-ui/core';

// import { Container } from './styles';

export default function CardTitle({ title, icoName }) {
  return (
    <>
      <CardContent>
        <Typography
          className="MuiTypography--heading"
          variant="h6"
          gutterBottom
        >
          <Icon
            style={{
              position: 'relative',
              top: '6px',
              marginRight: '1%',
            }}
          >
            {icoName}
          </Icon>
          {title}
        </Typography>
        <Divider variant="middle" component="header" />
      </CardContent>
    </>
  );
}

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icoName: PropTypes.string.isRequired,
};
