import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { ContainerDiv, StyledContainer, StyledPaper } from './styles';
import Occurrence from '~/components/Occurrence';
import OccurrenceList from '~/components/OccurrenceList';
import TicketComments from '~/components/TicketComments';
import CommentWrite from '~/components/CommentWrite';
import OccurrenceManager from '~/components/OccurrenceManager';
import RequestList from '~/components/RequestList';

class TicketDetailsCallCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Number(props.id),
    };
  }

  render() {
    const { id } = this.state;

    return (
      <ContainerDiv>
        <StyledContainer maxWidth="lg">
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} md={6} lg={6}>
              <StyledPaper>
                <Occurrence ocId={id} />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <StyledPaper>
                <OccurrenceManager ticketID={id} />
                {/* Tratativa da Ocorrência */}
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <StyledPaper>
                <OccurrenceList occurrence_id={id} />
                {/* Ocorrências Antigas (Atual) */}
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <StyledPaper>
                <RequestList id={id} />
                {/* Tratativas Antigas */}
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <StyledPaper>
                <CommentWrite ticketId={id} />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <StyledPaper>
                <TicketComments ticketId={id} />
              </StyledPaper>
            </Grid>
          </Grid>
        </StyledContainer>
      </ContainerDiv>
    );
  }
}

export default TicketDetailsCallCenter;

TicketDetailsCallCenter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
