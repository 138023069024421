import styled from 'styled-components';
import { styled as styledMaterial } from '@material-ui/styles';
import { TableCell } from '@material-ui/core';

export const Container = styled.div``;

export const ImageProfile = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
`;

export const CellEnable = styledMaterial(TableCell)({
  color: props => (props.enabled ? '#1a69a4' : '#f94d6a'),
  textDecoration: 'none',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  borderBottom: '1px solid',
  borderBottomColor: '#e0e0e0',
  textAlign: 'center',

  '&:hover': {
    borderColor: '#5a78a6',
    borderBottom: '1px solid',
    // border: '1',
  },
});

export const StyledCellHeader = styledMaterial(TableCell)({
  fontWeight: 'bold',
  textAlign: 'center',
});

export const StyledCellContent = styledMaterial(TableCell)({
  textAlign: 'center',
});

// text-decoration: none;
// background-color: Orange;
// color: #333333;
// padding: 2px 6px 2px 6px;
// border-top: 1px solid #CCCCCC;
// border-right: 1px solid #333333;
// border-bottom: 1px solid #333333;
// border-left: 1px solid #CCCCCC;
// border-radius: 8px;
