import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { MdAddCircleOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import Select from './SelectInput';
import { Container } from './styles';
import CardTitle from '../CardTitle';
import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  problem: Yup.number()
    .required()
    .typeError('Você precisa escolher uma das opções !'),
});

export default function OccurrenceManager({ ticketID }) {
  const [problemOptions, setProblemOptions] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [userRoleID, setUserRoleID] = useState();
  const loading = false;

  useEffect(() => {
    const getProblems = async () => {
      const { data } = await api.get(`problems`);
      data.forEach(d => {
        d.title = d.description;
      });
      setProblemOptions([...data]);
    };

    const getStatus = async () => {
      const {
        data: { ticket },
      } = await api.get(`ticket`, {
        params: {
          id: ticketID,
        },
      });

      if (ticket.status === 3) setButtonStatus(true);
    };

    const dataLocal = localStorage.getItem('persist:trp-oc');
    const parsedData = JSON.parse(dataLocal);
    const parsedUser = JSON.parse(parsedData.user);
    setUserRoleID(parsedUser.company.role.id);

    getProblems();
    getStatus();
  }, [ticketID]);

  const handleSubmit = async (data, { resetForm }) => {
    const { problem } = data;

    await api.post('ticket-request', {
      ticket_id: ticketID,
      problem_id: problem,
    });

    toast.success('Tratativa solicitada');

    setButtonStatus(true);
    history.goBack();
    resetForm();
  };

  const handleFinish = async () => {
    await api.put('ticket', {
      id: ticketID,
      status: 3,
    });

    toast.success('Ticket Finalizado com Sucesso');

    setButtonStatus(true);
    history.goBack();
  };

  return (
    <Container>
      <CardTitle title="Tratativa Ocorrência" icoName="report" />
      <Form schema={schema} onSubmit={handleSubmit}>
        <Select
          name="problem"
          placeholder="Selecione..."
          options={problemOptions || ['nenhum']}
          multiple={false}
        />
        <footer>
          <button type="button" onClick={handleFinish} disabled={buttonStatus}>
            <MdAddCircleOutline size={20} color="#fff" />
            <span>Finalizar</span>
          </button>

          {userRoleID === 1 || userRoleID !== 4 ? (
            ''
          ) : (
            <button type="submit" disabled={!buttonStatus}>
              <MdAddCircleOutline size={20} color="#fff" />
              <span>{!loading ? 'Prorrogar prazo' : 'Aguarde...'}</span>
            </button>
          )}

          <button type="submit" disabled={buttonStatus}>
            <MdAddCircleOutline size={20} color="#fff" />
            <span>{!loading ? 'Solicitar tratativa' : 'Aguarde...'}</span>
          </button>
        </footer>
      </Form>
    </Container>
  );
}

OccurrenceManager.propTypes = {
  typeOptions: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  ticketID: PropTypes.number.isRequired,
};

OccurrenceManager.defaultProps = {
  typeOptions: { id: 0, title: '' },
};
