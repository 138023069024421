import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { Container } from './styles';
import Card from '~/components/Card';
import DoubleBarChart from '~/components/DoubleBarChart';
import RecentTickets from '~/components/RecentTickets';
import api from '~/services/api';

export default function DashboardCarrier() {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const dashStats = async () => {
      const { data } = await api.get('dashboard-stats');
      setStats(data);
    };

    dashStats();
  }, []);

  const cards = [
    {
      cardTitle: 'Ocorrências Abertas',
      cardValue: stats.ticket ? stats.ticket.open : '0',
      cardIcon: 'check_circle_outline',
      cardColor: 'secondary',
    },
    {
      cardTitle: 'Ocorrências CallCenter',
      cardValue: stats.ticket ? stats.ticket.call_center : '0',
      cardIcon: 'explore',
      cardColor: 'error',
    },
    {
      cardTitle: 'Ocorrências Transportadora',
      cardValue: stats.ticket ? stats.ticket.carrier : '0',
      cardIcon: 'explore',
      cardColor: 'error',
    },
  ];

  return (
    <Container>
      <Grid container spacing={3} alignItems="stretch">
        {cards.map(card => (
          <Grid item xs={4} key={`${card.cardTitle}gi`}>
            <Card
              key={card.cardTitle}
              cardTitle={card.cardTitle}
              cardValue={card.cardValue}
              cardIcon={card.cardIcon}
              cardColor={card.cardColor}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <DoubleBarChart />
        </Grid>
        <Grid item xs>
          <RecentTickets />
        </Grid>
      </Grid>
    </Container>
  );
}
